<template>
  <div class="about">
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in swipeImg" :key="index"><img :src="item" width="100%" height="100%"></van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-row>
        <van-col span="8" class="border-right"><span v-text="count.discharging"></span><p>工作</p></van-col>
        <van-col span="8" class="border-right"><span v-text="count.charging"></span><p>充电</p></van-col>
        <van-col span="8"><span v-text="count.offline"></span><p>离线</p></van-col>
      </van-row>
    </div>
    <div class="dev-list">
        <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
          <div v-if="!devEmpty">
              <div v-if="devList.length != 0">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                    <div class="block glo-relative" v-for="(item,index) in devList" :key="index" @click="goDetail(item)">
                      <van-row :class="item.devStatus !== 'offline' ? 'online' :'offline'">
                          <van-col span="6">
                              <img :class="item.devStatus === 'offline' ? 'off-img-bg' :''" src="@/assets/icps/img/dev.png" width="55px" height="65px">
                          </van-col>
                          <van-col span="18">
                              <div class="title" v-text="item.devName"></div>
                              <div v-text="item.devCode"></div>
                              <!-- <div v-text="item.location || '设备暂未设置地址'"></div> -->
                          </van-col>
                          <div class="position-tags" v-if="item.devStatus !== 'offline'">
                            <div class="bat" v-if="item.chargeStatus === 'charging'">
                              <img src="@/assets/icps/img/bat-charge.png">
                            </div>
                            <div class="bat" v-else>
                              <img src="@/assets/icps/img/bat-alarm.png" v-if="Number(item.batLevel) <= 20">
                              <img src="@/assets/icps/img/bat-1.png" v-if="Number(item.batLevel) <= 25 && Number(item.batLevel) > 20">
                              <img src="@/assets/icps/img/bat-2.png" v-if="Number(item.batLevel) <= 50 && Number(item.batLevel) > 25">
                              <img src="@/assets/icps/img/bat-3.png" v-if="Number(item.batLevel) <= 75 && Number(item.batLevel) > 50">
                              <img src="@/assets/icps/img/bat-4.png" v-if="Number(item.batLevel) <= 100 && Number(item.batLevel) > 75">
                            </div>
                            <div><i v-if="item.tempAlarm === '1'" class="iconfont">&#xe79f;</i></div>
                          </div>
                      </van-row>
                    </div>
                </van-list>
              </div>
              <div v-else><van-empty description="设备列表为空" /></div>
          </div>
          <div v-else><van-loading class="z-loading" size="24px" vertical>数据努力加载中...</van-loading></div>
        <!-- </van-pull-refresh> -->
      </div>
    <tabBar />
  </div>
</template>

<script>
import tabBar from '@/components/icps/tabBar.vue'

export default {
  name: 'devIndex',
  components: {
    tabBar
  },
  data(){
    return{
      swipeImg:[
        require('@/assets/icps/img/banner2.png'),
        require('@/assets/icps/img/banner1.png'),
        require('@/assets/icps/img/banner3.png'),
      ],
      count:{
        offline:0,
        charging:0,
        discharging: 0
      },
      loading:false,
      finished:false,
      pageSize:100,
      pageNum:1,
      devList:[],
      devEmpty:true,
      timer:null,
      projectCode:null,
      // isLoading: false,
    }
  },
  methods:{
      // 点击设备进入详情页面
      goDetail(item){
        if(this.visitor == '1'){
          this.$toast('暂无权限进入设备操控面板');
          return false;
        }
        this.$router.push({path:'/icps/dev/detail',query: {devCode:item.devCode}})
      },
      // 获取分页数据信息
      onLoad(){
        this.$api.ICPS.devListPage(this.pageNum,this.pageSize,{projectCode:this.projectCode}).then((d)=>{
            this.devEmpty = false;
            if(Array.isArray(d)){
              this.loading = false
              // this.devList = this.devList.concat(d.rows);
              this.devList = d;
              // 加载完毕
              if( d.length < this.pageSize ){
                this.finished = true
                return
              }
              this.pageNum ++;
            }
        })
      },
      // 下拉刷新
      // onRefresh() {
      //   this.finished = false;
      //   this.pageNum = 1;
      //   this.onLoad('empty');
      //   setTimeout(() => {
      //     this.isLoading = false;
      //   }, 2800);
      // }
      getCount(){
        this.$api.ICPS.devCount({projectCode:this.projectCode}).then((data)=>{
            this.count = {...data}
        })
      }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.visitor = localStorage.getItem('visitor');
      this.onLoad();
      // 获取设备数
      this.getCount();
      // 定时任务-轮询
      this.timer = setInterval(()=>{
          this.onLoad()
          this.getCount()
      },30*1000)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.info{
    padding: 4%;
    background: #019af3;
    color: white;
    margin-top: -2%;
    .border-right{border-right:1px solid #f0f0f0}
    span{font-size: 1.5rem;font-weight: bold;}
    p{margin: 0;font-size: .9rem;}
}
.dev-list{
    margin-bottom: 15%;
    .block{
        border: 1px solid #f5f5f5;
        background-color: white;
        padding: 4%;
        margin-bottom: 10px;
        font-size: .8rem;
        text-align: left;
        box-shadow: 0 2px 4px #e4e4e4;
        .online{color:#019af3;}
        .offline{color:#8d8d8d;}
        img{display: block;margin: 0 auto;}
        .title{
            font-weight: bold;
            line-height: 2.5;
        }
    }
}
.position-tags{
  position: absolute;
  top: 6%;
  right: 2%;
  color: #ff3838;
  display: flex;
  flex-direction: row;
  align-items: center;
  &>i{
    font-size: 1.3rem;
    margin-right: 5px;
  }
  .green{
    color: rgb(6, 175, 20);
  }
  .blue{
    color: blue;
  }
  .bat{
    margin-right: 5px;
    img{
      width: 25px;
      height: 100%;
    }
  }
}
.off-img-bg{
  opacity: 0.8;
}
</style>